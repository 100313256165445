export function scrollElements(selector, margins, className) {
  const isInViewport = function (elem) {
      const distance = elem.getBoundingClientRect();
      const middle = $(window).height() / 2; 
      return (
          distance.top >= middle - margins &&
          distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) - middle + margins
      );
  };
  
  const elements = document.querySelectorAll(selector);
  
  window.addEventListener('scroll', function (event) {
      elements.forEach(element => {
          if (isInViewport(element)) {
              element.classList.add(className);
          } else {
              element.classList.remove(className);
          }
      });
  }, false);
}